import delve from 'dlv'

const moment = require('moment')

export const sendConfirmationEmails = (data) => {
  const url = '/.netlify/functions/order-confirmation-emails'

  const chars = delve(data, 'values.chars.characteristics.default')
  const color = delve(data, 'values.bouquet.color.default')

  if (chars) {
    data.values.chars.characteristics =
      'Gerne überlasse ich die Wahl Atelier Cartier'
  } else {
    data.values.chars.characteristics = Object.keys(
      data.values.chars.characteristics
    )
      .filter((key) => key !== 'default')
      .filter((key) => data.values.chars.characteristics[key] === true)
      .join(', ')
  }

  if (color) {
    data.values.bouquet.color = 'Gerne überlasse ich die Wahl Atelier Cartier'
  } else {
    data.values.bouquet.color = Object.keys(data.values.bouquet.color)
      .filter((key) => key !== 'default')
      .filter((key) => data.values.bouquet.color[key] === true)
      .join(', ')
  }

  const method = delve(data, 'values.delivery.method')
  if (method === 'Pick-up' || method === 'Selbstabholung') {
    data.values.delivery.self = true
  }

  const date = delve(data, 'values.delivery.date')
  if (date) {
    data.values.delivery.date = moment(data.values.delivery.date).format(
      'DD.MM.YYYY'
    )
  }

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      emailTo: delve(data, 'values.payment.address.email'),
      data
    })
  })
}
