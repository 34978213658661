import { v4 as uuidv4 } from 'uuid'

const USER_ID = 'user-id'

export const getLocalStorageUserId = () => {
  let userId = localStorage.getItem(USER_ID)

  if (!userId) {
    const uuid = uuidv4()

    userId = uuid

    localStorage.setItem(USER_ID, userId)
  }

  return userId
}

export const getLocalStorageFormData = (userId) => {
  return JSON.parse(localStorage.getItem(userId))
}

export const setLocalStorageFormData = (userId, data) => {
  localStorage.setItem(userId, JSON.stringify(data))
}

export const removeLocalStorageFormData = (userId) => {
  localStorage.removeItem(userId)
  localStorage.removeItem(USER_ID)
}
