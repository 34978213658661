import React from 'react'

import Layout from '../components/Layout'
import Success from '../components/Success'

export default ({ location, pageContext }) => (
  <Layout pageTitle='Success' location={location} locale={pageContext.locale}>
    <Success location={location} locale={pageContext.locale} />
  </Layout>
)
