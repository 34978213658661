import styled from 'styled-components'
import media from '../../utils/media'
import { Field } from 'formik'

/* Containers */
export const Form = styled.div`
  font-size: 20px;
  line-height: 30px;

  color: #000;
  word-break: keep-all;

  ${media.lessThan('sm')`
    font-size: 16px;
    line-height: 24px;
  `};
  br {
    opacity: 0;
  }
`
export const Stage = styled.div`
  z-index: 2;
  position: absolute;
  top: ${({ main }) => (main ? '50px' : '100px')};
  left: 0;

  width: 100%;
  height: ${({ main }) => (main ? 'calc(100% - 50px)' : 'calc(100% - 100px)')};

  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }) => (visible ? '1' : '0')};
`

export const StageBody = styled.div`
  position: absolute;
  width: 100%;
`

export const Grid = styled.div`
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  overflow: hidden;
  margin-bottom: 20px;
  padding-left: 70px;
  padding-right: 70px;

  ${media.lessThan('sm')`
    padding-left: 16px;
    padding-right: 16px;
    -ms-flex-direction: column;
    flex-direction: column;
    & > * {
      padding-right: 25px;
    }
  `};
  ${media.greaterThan('sm')`
    max-width: 1800px;
    -ms-flex-direction: row;
    flex-direction: row;
    & > * {
      width: 25%;
      padding-right: 40px;
    }
  `};

  ${media.greaterThan('1940px')`
    padding-left: calc((100% - 1800px) / 2);
  `};
`
export const Card = styled(Grid)`
  ${media.greaterThan('sm')`
    & > div:nth-child(1) {
      width: 30%;
      padding: 0;
      & .label {
        display: inline-block;
        white-space: nowrap;
      }
    }
    & > div:nth-child(2) {
      width: 70%;
    }
  `};

  textarea {
    height: 464px;
    font-size: 60px;
    line-height: 64px;
    text-align: center;
    padding-top: 210px;

    ${media.lessThan('sm')`
      height: 100px;
      font-size: 16px;
      line-height: 24px;
      padding-top: 20px;
    `};

    &:disabled {
      opacity: 0.2;
    }
  }
`
export const Center = styled.div`
  background-color: whitesmoke;
  text-align: center;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  z-index: 3;
  position: ${({ next, cancel }) => (next || cancel ? 'fixed' : 'static')};
  ${({ next }) => (next ? 'bottom: 50px;' : '')};
  ${media.lessThan('sm')`${({ next }) => (next ? 'bottom: 40px;' : '')};`};
  ${({ cancel }) => (cancel ? 'bottom: 0;' : '')};

  ${({ hidden }) => (hidden ? 'display: none' : '')};
`
export const Paypal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  height: 100%;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  z-index: 5;
  & > div {
    margin-top: 20px;
  }
`

/* Text at the start */
export const StartText = styled.div`
  padding-top: 50px;
  padding-bottom: 100px;
  p {
    margin: auto;
    width: 50%;
    text-align: center;
    font-family: inherit;
    font-size: 40px;
    line-height: 44px;
    font-weight: 400;
    ${media.lessThan('sm')`
      font-size: 16px;
      line-height: 22px;
      width:90%
    `};
  }
`

/* Top bar */
export const ActionBar = styled.ul`
  z-index: 3;
  background-color: whitesmoke;
  white-space: nowrap;
  position: fixed;
  top: 50px;
  width: 100%;
  height: 52px;
  margin: 0;
  padding: 0;

  border-bottom: 2px solid #fff;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  list-style: none;
  justify-content: center;
  align-items: center;

  img {
    ${media.lessThan('400px')`display: none;`};
    position: absolute;
    right: 10px;
    margin: 0;
    max-width: 30px;
    cursor: pointer;
    right: 10px;
    &:nth-child(1) {
      left: 10px;
    }
  }
`
export const Li = styled.li`
  display: flex;
  align-items: center;
  height: 100%;
  width: auto;

  margin: 0;
  padding: 0 15px;
  ${media.lessThan('sm')`padding: 0 10px;`};
  ${media.lessThan('400px')`padding: 0 5px;`};

  display: ${({ active }) => (active ? '1' : '0.4')};
  cursor: ${({ disabled }) => (disabled ? 'pointer' : 'auto')};

  span {
    overflow: hidden;
    display: inline-block;
  }
  span.nr {
    width: auto;
  }

  &:hover span.nr ~ span.title {
    max-width: ${({ disabled }) => (disabled ? '145px;' : '0px;')};
  }
`

export const SpanTitle = styled.span`
  ${media.greaterThan('400px')`padding-left: 10px;`};
  ${media.lessThan('400px')`padding-left: 5px;`};
  transition: max-width 1s ease-out;
  max-width: ${({ visible }) => (visible ? '145px' : '0px')};
  &:hover {
    max-width: 145px;
  }
`

export const Group = styled.div`
  padding-top: 10px;
`
export const Title = styled.div`
  padding-top: 12px;
  color: ${({ error }) => (error ? 'red' : 'black')};
  &::after {
    content: ${({ error }) => (error ? '" *"' : '""')};
  }
`

export const Error = styled.small`
  display: block;
  position: relative;

  margin-top: 5px;
  padding-left: 40px;

  color: red;
  font-size: 15px;
  line-height: 20px;

  &::before {
    content: '*';
    position: absolute;
    top: 0;
    left: 0;
  }
`
export const ErrorText = styled.div`
  color: red;
  margin: 10px 0;
`

/* Inputs */
export const TextArea = styled(Field)`
  width: 100%;
  height: auto;
  border: 0;
  border-radius: 0;
  resize: none;

  height: 300px;
  ${media.lessThan('sm')`
    height: 100px;
  `};

  padding-top: 12px;
  margin: 0;

  background-color: transparent;
  color: black;

  &:focus {
    outline: 0;
  }

  color: ${({ error }) => (error ? 'red' : 'black')};
`
export const Input = styled(Field)`
  width: 100%;
  border: none;
  margin-top: 10px;
  color: black;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5px 0 5px 20px;
  border-left: ${({ error }) => (error ? '2px solid red' : 'none')};

  ${media.lessThan('sm')`
    width: 80%;
  `};

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

export const DateContainer = styled.div`
  & .date-input {
    width: 100%;
    border: none;
    margin-top: 10px;
    color: black;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 5px 0 5px 20px;
    border-left: ${({ error }) => (error ? '2px solid red' : 'none')};

    ${media.lessThan('sm')`
      width: 80%;
    `};
  }
`

/* Custom radio and checkbox */
export const Label = styled.label`
  display: block;
  position: relative;
  word-wrap: normal;
  padding-left: 40px;
  color: ${({ error }) => (error ? 'red' : 'black')};

  & input:checked ~ span {
    background-color: rgba(0, 0, 0, 0.4);
    border: 2px solid transparent;
  }

  & input:checked ~ span:after {
    display: block;
  }
`
export const Radio = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 0;
  width: 0;
`
export const Checkbox = styled(Field)`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 0;
  width: 0;
`
export const Checkmark = styled.span`
  position: absolute;
  top: 0.15em;
  left: 0;
  height: 24px;
  width: 24px;
  ${media.lessThan('sm')`
    height: 20px;
    width: 20px;
  `};

  background-color: transparent;
  border: 2px solid rgba(0, 0, 0, 0.4);
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }
`

/* Buttons */
const BottomBtn = styled.div`
  z-index: 5;
  padding: 0;
  width: 20%;
  border: none;
  border-color: transparent;
  cursor: pointer;
  text-align: center;
  &:focus {
    outline: 0;
  }
  ${media.lessThan('sm')`
    width: calc(100% - 34px);
  `};
  &[type='submit'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`
export const NextBtn = styled(BottomBtn)`
  color: white;
  background-color: black;
  font-size: 20px;
  line-height: 50px;
  opacity: 0.6;
  ${media.lessThan('sm')`
    font-size: 16px;
    line-height: 40px;
  `};
`
export const CancelBtn = styled(BottomBtn)`
  color: black;
  background-color: whitesmoke;
  font-size: 12px;
  line-height: 50px;
  ${media.lessThan('sm')`
    font-size: 10px;
    line-height: 40px;
  `};
`

/* Summary */
export const Summary = styled(Grid)`
  padding-top: 12px;
  ${media.greaterThan('sm')`
    -ms-flex-direction: column;
    flex-direction: column;
    &>*{width:100%;}
  `};
`
export const Link = styled.a`
  text-decoration: none;
  color: black;
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
`
