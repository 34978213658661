module.exports = {
  de: {
    chars: {
      title: 'Eigenschaften',
      gender: {
        title: 'Geschlecht',
        values: ['Keine Angabe', 'Frau', 'Mann']
      },
      characteristics: {
        title: 'Eigenschaften (max. 3)',
        default: 'Gerne überlasse ich die Wahl Atelier Cartier',
        values: [
          'Klassisch',
          'Elegant',
          'Humorvoll',
          'Streng',
          'Naturverbunden',
          'Romantisch',
          'Verspielt',
          'Verrückt',
          'Extravagant',
          'Kühl',
          'Ordentlich',
          'Exotisch',
          'Bunt',
          'Traditionell',
          'Verträumt'
        ]
      },
      age: {
        title: 'Alter',
        values: [
          'Keine Angabe',
          'Unter 20 Jahre',
          '20 bis 35 Jahre',
          '36 bis 50 Jahre',
          '51 bis 60 Jahre',
          'Über 60 Jahre'
        ]
      },
      story: { title: 'Story' }
    },
    bouquet: {
      title: 'Strauss',
      shape: {
        title: 'Form',
        values: [
          'Gerne überlasse ich die Wahl Atelier Cartier',
          'Rund gebunden',
          'Hoch gebunden'
        ]
      },
      size: {
        title: 'Grösse',
        values: ['Standard', 'Medium', 'Large', 'Eigene Grösse'],
        prices: ['CHF 100.—', 'CHF 150.—', 'CHF 180.—', '(ab CHF 101.—)']
      },
      color: {
        title: 'Farbe (max. 3)',
        default: 'Gerne überlasse ich die Wahl Atelier Cartier',
        values: [
          'Weiss',
          'Gelb',
          'Orange',
          'Pink',
          'Rosa',
          'Rot',
          'Lila',
          'Blau',
          'Grün',
          'Bunt'
        ]
      },
      comments: { title: 'Anmerkungen' }
    },
    card: {
      title: 'Karte',
      card: {
        title: 'Karte',
        values: ['mit Karte (+ CHF 3.—)', 'ohne Karte']
      },
      card_text: {}
    },
    delivery: {
      title: 'Lieferung',
      pickup_title: 'Uhrzeit',
      method: { values: ['Lieferadresse', 'Selbstabholung'] },
      address: {
        given_name: 'Vorname',
        family_name: 'Name',
        company: 'Firma',
        street: 'Strasse',
        house_number: 'Hausnummer',
        postal_code: 'Postleitzahl',
        city: 'Ort',
        tel: 'Tel',
        email: 'E-mail'
      },
      date: { title: 'Lieferdatum', pickup_title: 'Abholdatum' },
      pickup_address: {
        title: 'Abholadresse',
        name: 'Atelier Cartier',
        street: 'Turnerstrasse 9',
        city: '8006 Zürich'
      },
      time: {
        title: 'Lieferzeit',
        values: [
          '10:00-12:00 ',
          '12:00-14:00 ',
          '14:00-16:00 ',
          '16:00-18:00 ',
          'Bestimmte Uhrzeit'
        ]
      },
      price: {
        title: 'Lieferpreis',
        values: {
          life_zone: 'Lieferzone',
          urgent_delivery: 'Eilbestellung',
          specific_time: 'Bestimmte Uhrzeit',
          rush_hour: 'Rush hour',
          pickup_time: 'Selbstabholung'
        }
      },
      comments: { title: 'Anmerkungen' }
    },
    payment: {
      title: 'Bezahlung',
      address_title: 'Rechnungsadresse',
      address: {
        given_name: 'Vorname',
        family_name: 'Name',
        company: 'Firma',
        street: 'Strasse',
        house_number: 'Hausnummer',
        postal_code: 'Postleitzahl',
        city: 'Ort',
        tel: 'Tel',
        email: 'E-mail'
      },
      button: 'gleich wie Lieferadresse',
      method: {
        title: 'Zahlungsarten',
        values: ['PayPal', 'Kreditkarte / Postfinance / Twint']
      }
    },
    summary: {
      title: 'Übersicht',
      accept: 'Ich akzeptiere die',
      link: 'AGBs',
      delivery_string: 'Lieferung',
      pickup_string: 'Selbstabholung',
      newsletter: 'Ich möchte den Newsletter abonnieren',
      price_total: 'Total',

      paypal: 'Um zu Paypal zu kommen auf folgenden Link klicken:',
      success_email: 'Sie erhalten sogleich Ihre Bestätigung per E-Mail.',
      success: 'Vielen Dank für Ihre Bestellung!',
      return: 'Home'
    },

    buttons: {
      start_button: 'Bestellung starten',
      next_button: 'Weiter',
      cancel_button: 'Abbrechen',
      submit_button: 'Jetzt bestellen',
      summary_change: 'Ändern',
      privacy_policy: 'Datenschutzerklärung'
    },
    email: {
      vendor_body: {
        mail_subject: 'Eine Bestellung für Atelier Cartier',
        default: 'Gerne überlasse ich die Wahl Atelier Cartier',

        person: 'Person',
        gender: 'Geschlecht',
        characteristics: 'Eigenschaften',
        age: 'Alter',
        story: 'Story',

        bouquet: 'Strauss',
        price: 'Preis',
        shape: 'Form',
        color: 'Farbe',
        bouquet_notes: 'Bemerkung',
        currency: 'CHF',

        card: 'Karte',
        card_text: 'Kartentext',
        yes: 'Ja',
        no: 'Nein',

        from: 'Bestellung erhalten von',
        name: 'Name',
        company: 'Name/Firma',
        street: 'Strasse',
        city: 'PLZ/Ort',
        email: 'Email',
        tel: 'Telefon',
        payment_notes: 'Mitteilung',

        address: 'Lieferadresse',
        delivery: 'Lieferung',
        delivery_type: 'Lieferart',
        delivery_time: 'Lieferzeit',
        delivery_costs: 'Lieferpreis',
        express_delivery: 'Aufpreis Eilbestellung',
        total: 'Bestellungstotal',

        payment: 'Zahlungsmethode',

        newsletter: 'Newsletter',
        orderNr: 'Order Nr.'
      },

      client_body: {
        mail_subject: 'Ihre Bestellung bei Atelier Cartier',
        pretext: `Liebe(r) [firstname] [name], herzlichen Dank für Ihre Bestellung bei Atelier Cartier. Wir freuen uns bereits darauf Ihren einzigartigen Strauss anfertigen zu dürfen! Untenstehend finden Sie Ihre Bestellübersicht.`,
        aftertext: `Die Bestellung wird nach Eingang der Zahlung bearbeitet. Sie erhalten vom gewählten Zahlungsprovider eine separate Zahlungsbestätigung. Bei allfälligen Fragen stehen wir Ihnen gerne auch telefonisch zur Verfügung.<br/><br/>Freundliche Grüsse<br/><br/>Nicole Cartier<br/>Inhaberin<br/>Atelier Cartier<br/>Turnerstrasse 9<br/>8006 Zürich<br/>079 770 72 92<br/>blumen@ateliercartier.ch<br/>www.ateliercartier.ch`,
        default: 'Gerne überlasse ich die Wahl Atelier Cartier',

        recipient: 'Empfänger(in)',
        gender: 'Geschlecht',
        characteristics: 'Eigenschaften',
        age: 'Alter',
        story: 'Story',

        bouquet: 'Strauss',
        price: 'Preis',
        shape: 'Form',
        color: 'Farbe',
        bouquet_notes: 'Bemerkung',
        currency: 'CHF',

        card: 'Karte',
        card_text: 'Kartentext',
        yes: 'Ja',
        no: 'Nein',

        address: 'Lieferadresse',
        company: 'Name/Firma',
        street: 'Strasse',
        city: 'PLZ/Ort',
        tel: 'Telefon',

        pick_up: 'Abholadresse',
        pickup_address: 'Atelier Cartier<br/>Turnerstrasse 9<br/>8006 Zürich',

        from: 'Bestellung erhalten von',
        name: 'Name',
        email: 'Email',
        payment_notes: 'Mitteilung',

        delivery: 'Lieferung',
        delivery_type: 'Lieferart',
        delivery_time: 'Lieferzeit',
        delivery_costs: 'Lieferpreis',
        express_delivery: 'Aufpreis Eilbestellung',
        total: 'Bestellungstotal',
        vat: '(nicht Mehrwertsteuerpflichtig)',
        payment: 'Zahlungsmethode'
      }
    },
    locale: 'de',
    lang_code: 'de_CH'
  },
  en: {
    chars: {
      title: 'Characteristics',
      gender: {
        title: 'Gender',
        values: ['prefer not to say', 'woman', 'man']
      },
      characteristics: {
        title: 'Characteristics (max.3)',
        default: 'I will let Atelier Cartier decide.',
        values: [
          'Classic',
          'Elegant',
          'Humorous',
          'Strict',
          'Nature-lover',
          'Romantic',
          'Playful',
          'Crazy',
          'Extravagant',
          'Distant',
          'Orderly',
          'Exotic',
          'Colorful',
          'Traditional',
          'Dreamy'
        ]
      },
      age: {
        title: 'Age',
        values: [
          'prefer not to say',
          'under 20',
          'between 20 and 35',
          'between 36 and 50',
          'between 51 and 60',
          'over 60'
        ]
      },
      story: { title: 'Story' }
    },
    bouquet: {
      title: 'Bouquet',
      shape: {
        title: 'Shape',
        values: ['I will let Atelier Cartier decide.', 'round', 'tall']
      },
      size: {
        title: 'Size',
        values: ['Standard', 'Medium', 'Large', 'Specific size'],
        prices: ['CHF 100.—', 'CHF 150.—', 'CHF 180.—', '(from CHF 101.—)']
      },
      color: {
        title: 'Color (max. 3)',
        default: 'I will let Atelier Cartier decide.',
        values: [
          'White',
          'Yellow',
          'Orange',
          'Pink',
          'Rose',
          'Red',
          'Purple',
          'Blue',
          'Green',
          'Colorful'
        ]
      },
      comments: { title: 'Comments' }
    },
    card: {
      title: 'Card',
      card: {
        title: 'Card',
        values: ['with card (+ CHF 3.—)', 'without card']
      },
      card_text: {}
    },
    delivery: {
      title: 'Delivery',
      pickup_title: 'Hour',
      method: { values: ['Delivery address', 'Pick-up'] },
      address: {
        given_name: 'Given name',
        family_name: 'Family name',
        company: 'Company',
        street: 'Street',
        house_number: 'House number',
        postal_code: 'Postal code',
        city: 'City',
        tel: 'Tel',
        email: 'E-mail'
      },
      pickup_address: {
        title: 'Address',
        name: 'Atelier Cartier',
        street: 'Turnerstrasse 9',
        city: '8006 Zürich'
      },
      date: { title: 'Delivery date', pickup_title: 'Pick-up date' },
      time: {
        title: 'Delivery time',
        values: [
          '10:00-12:00',
          '12:00-14:00',
          '14:00-16:00',
          '16:00-18:00',
          'Specific time'
        ]
      },
      price: {
        title: 'Lieferpreis',
        values: {
          life_zone: 'Lieferzone',
          urgent_delivery: 'Eilbestellung',
          specific_time: 'Bestimmte Uhrzeit',
          rush_hour: 'Rush hour',
          pickup_time: 'Pick-up'
        }
      },
      comments: { title: 'Comments' }
    },
    payment: {
      title: 'Payment',
      address_title: 'Billing address',
      address: {
        given_name: 'Given name',
        family_name: 'Family name',
        company: 'Company',
        street: 'Street',
        house_number: 'House number',
        postal_code: 'Postal code',
        city: 'City',
        tel: 'Tel',
        email: 'E-mail'
      },
      button: 'Same as delivery address',
      method: {
        title: 'Payment methods',
        values: ['PayPal', 'Kreditkarte / Postfinance / Twint']
      }
    },
    summary: {
      title: 'Summary',
      accept: 'I accept the',
      link: 'terms and conditions',
      newsletter: 'I would like to subscribe to the newsletter.',
      delivery_string: 'Delivery',
      pickup_string: 'Pick-up',
      price_total: 'Total price',
      paypal: 'Um zu Paypal zu kommen auf folgenden Link klicken:',
      success_email: 'You will receive the confirmation by email.',
      success: 'Thank you for your order!',
      return: 'Home'
    },
    buttons: {
      start_button: 'Start order',
      next_button: 'Next',
      cancel_button: 'Cancel',
      submit_button: 'Order now',
      summary_change: 'change',
      privacy_policy: 'Privacy policy'
    },
    email: {
      vendor_body: {
        mail_subject: 'Eine Bestellung für Atelier Cartier',
        default: 'Gerne überlasse ich die Wahl Atelier Cartier',

        person: 'Person',
        gender: 'Geschlecht',
        characteristics: 'Eigenschaften',
        age: 'Alter',
        story: 'Story',

        bouquet: 'Strauss',
        price: 'Preis',
        shape: 'Form',
        color: 'Farbe',
        bouquet_notes: 'Bemerkung',
        currency: 'CHF',

        card: 'Karte',
        card_text: 'Kartentext',
        yes: 'Ja',
        no: 'Nein',

        from: 'Bestellung erhalten von',
        name: 'Name',
        company: 'Name/Firma',
        street: 'Strasse',
        city: 'PLZ/Ort',
        email: 'Email',
        tel: 'Telefon',
        payment_notes: 'Mitteilung',

        address: 'Lieferadresse',
        delivery: 'Lieferung',
        delivery_type: 'Lieferart',
        delivery_time: 'Lieferzeit',
        delivery_costs: 'Lieferpreis',
        express_delivery: 'Aufpreis Eilbestellung',
        total: 'Bestellungstotal',

        payment: 'Zahlungsmethode',

        newsletter: 'Newsletter',
        orderNr: 'Order Nr.'
      },

      client_body: {
        mail_subject: 'Ihre Bestellung bei Atelier Cartier',
        pretext: `Liebe(r) [firstname] [name], herzlichen Dank für Ihre Bestellung bei Atelier Cartier. Wir freuen uns bereits darauf Ihren einzigartigen Strauss anfertigen zu dürfen! Untenstehend finden Sie Ihre Bestellübersicht.`,
        aftertext: `Die Bestellung wird nach Eingang der Zahlung bearbeitet. Sie erhalten vom gewählten Zahlungsprovider eine separate Zahlungsbestätigung. Bei allfälligen Fragen stehen wir Ihnen gerne auch telefonisch zur Verfügung.<br/><br/>Freundliche Grüsse<br/><br/>Nicole Cartier<br/>Inhaberin<br/>Atelier Cartier<br/>Turnerstrasse 9<br/>8006 Zürich<br/>079 770 72 92<br/>blumen@ateliercartier.ch<br/>www.ateliercartier.ch`,
        default: 'Gerne überlasse ich die Wahl Atelier Cartier',

        recipient: 'Empfänger(in)',
        gender: 'Geschlecht',
        characteristics: 'Eigenschaften',
        age: 'Alter',
        story: 'Story',

        bouquet: 'Strauss',
        price: 'Preis',
        shape: 'Form',
        color: 'Farbe',
        bouquet_notes: 'Bemerkung',
        currency: 'CHF',

        card: 'Karte',
        card_text: 'Kartentext',
        yes: 'Ja',
        no: 'Nein',

        address: 'Lieferadresse',
        company: 'Name/Firma',
        street: 'Strasse',
        city: 'PLZ/Ort',
        tel: 'Telefon',

        pick_up: 'Abholadresse',
        pickup_address: 'Atelier Cartier<br/>Turnerstrasse 9<br/>8006 Zürich',

        from: 'Bestellung erhalten von',
        name: 'Name',
        email: 'Email',
        payment_notes: 'Mitteilung',

        delivery: 'Lieferung',
        delivery_type: 'Lieferart',
        delivery_time: 'Lieferzeit',
        delivery_costs: 'Lieferpreis',
        express_delivery: 'Aufpreis Eilbestellung',
        total: 'Bestellungstotal',
        vat: '(nicht Mehrwertsteuerpflichtig)',
        payment: 'Zahlungsmethode'
      }
    },
    locale: 'en',
    lang_code: 'en_GB'
  }
}
