import React from 'react'
import { navigate } from 'gatsby'

import {
  getLocalStorageUserId,
  getLocalStorageFormData,
  removeLocalStorageFormData
} from '../../utils/localStorage'

import * as El from '../Order/styles'

import form_strings from '../../constants/form'
import { sendConfirmationEmails } from './sendConfirmationEmails'

const Success = ({ location }) => {
  const [locale, set_locale] = React.useState('de')

  React.useEffect(() => {
    const params = new URL(location.href).searchParams
    const userId = params.get('userId')

    if (userId !== getLocalStorageUserId()) {
      goToHome()
    }
  }, [])

  React.useEffect(() => {
    const params = new URL(location.href).searchParams
    set_locale(params.get('locale') || 'de')
  }, [])

  React.useEffect(() => {
    const userId = getLocalStorageUserId()

    if (userId) {
      const formData = getLocalStorageFormData(userId)

      if (formData) {
        sendConfirmationEmails(formData)
        removeLocalStorageFormData(userId)
      }
    }
  }, [])

  const form_s = form_strings[locale]

  const goToHome = () => navigate(`${locale === 'de' ? '/' : '/en/'}`)

  return (
    <El.Form>
      <El.Paypal visible>
        <div>{form_s.summary.success_email}</div>
        <div>{form_s.summary.success}</div>
        <El.NextBtn onClick={goToHome}>{form_s.summary.return}</El.NextBtn>
      </El.Paypal>
    </El.Form>
  )
}

export default Success
